<template>
  <el-row class="movie-list">
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>上传视频文件</span>
          </div>
          <div class="text item">
            <uploader
              v-if="options !== null"
              class="uploader-example"
              :options="options"
              :auto-start="true"
              @file-added="onFileAdded"
              @file-success="onFileSuccess"
              @file-progress="onFileProgress"
              @file-error="onFileError"
            >
              <uploader-unsupport />
              <uploader-drop>
                <p>拖动视频文件到此处或</p>
                <uploader-btn :attrs="attrs">选择视频文件</uploader-btn>
              </uploader-drop>
              <uploader-list />
            </uploader>
          </div>
        </el-card>
      </el-col>
      <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>上传视频封面</span>
          </div>
          <div class="text item">
            <el-tooltip class="item" effect="dark" content="点击上传图片" placement="top-end">
              <el-upload
                class="avatar-uploader"
                :action="imgOssUrl"
                :headers="imgHeaders"
                :data="imgData"
                :with-credentials="false"
                :show-file-list="false"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
                :on-error="handleAvatarError"
                :on-change="handleOnChange"
              >
                <img v-if="coverUrl" :src="coverUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon" />
              </el-upload>
            </el-tooltip>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
      <el-col :md="12" style="padding-right: 5px; padding-left: 5px; padding-bottom: 5px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>稿件信息</span>
            <el-button style="float: right; padding: 3px 0" type="text" @click="onSubmit">发布</el-button>
          </div>
          <div class="text item">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item label="标题">
                <el-input v-model="form.title" style="padding-right: 1px" placeholder="标题不能超过 50 个字符" />
              </el-form-item>
              <el-form-item label="描述">
                <el-input v-model="form.description" type="textarea" autosize style="padding-right: 1px;" />
              </el-form-item>
              <el-form-item label="分区">
                <el-select v-model="form.categoryPid" placeholder="请选择分区" @change="getCategory">
                  <el-option
                    v-for="item in pCategoryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
                <el-select v-model="form.categoryId" placeholder="请选择子分区">
                  <el-option
                    v-for="item in categoryList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
              <el-form-item label="标签">
                <el-select v-model="form.tags" style="padding-right: 1px" placeholder="输入标签，用回车添加" clearable multiple filterable allow-create default-first-option @change="getRecommendTags">
                  <el-option v-for="item in rcmdTags" :key="item.value" :label="item.label" :value="item.label" />
                </el-select>
              </el-form-item>
              <el-form-item label="可见范围">
                <el-select v-model="form.scope" placeholder="选择稿件的可见范围">
                  <el-option label="本人可见" value="1" />
                  <el-option label="所有人可见" value="2" />
                  <el-option label="VIP 可见" value="3" />
                  <el-option label="验证码可见" value="4" />
                </el-select>
              </el-form-item>
              <el-form-item label="定时发布">
                <el-date-picker
                  v-model="scheduledPubDate"
                  type="datetime"
                  placeholder="选择定时发布的时间"
                />
              </el-form-item>
            </el-form>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import { videoRegion } from '@/api/video'
import { getVideoChannelInfo, getVideoCoverChannelInfo } from '@/api/file'
import { hashFile } from '@/utils/functions'

export default {
  name: 'VideoPostPublish',
  data() {
    return {
      // ****************************************************************************************************************
      options: null,
      attrs: {
        accept: '*'
      },
      // ****************************************************************************************************************
      imgOssUrl: '',
      imgHeaders: {
        Authorization: ''
      },
      imgData: {
        channelCode: null
      },
      coverUrl: null,
      // ****************************************************************************************************************
      categoryMap: {
        Set: function(key, value) { this[key] = value },
        Get: function(key) { return this[key] },
        Contains: function(key) { return this.Get(key) !== null },
        Remove: function(key) { delete this[key] }
      },
      pCategoryList: [],
      categoryList: [],
      rcmdTags: [
        /* { label: "知识点1" }*/
      ],
      form: {
        videoFileId: null,
        channelCode: 0,
        coverFileId: null,
        coverChannelId: 0,
        title: null,
        description: null,
        categoryPid: null,
        categoryId: null,
        tags: [],
        scope: '2',
        scheduledTime: null
      },
      scheduledPubDate: null
    }
  },
  created() {
    getVideoChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.form.channelCode = resData.channelCode
        this.options = {
          target: resData.ossUrl,
          // 分块大小 10MB
          chunkSize: 1024 * 1024 * 10,
          // 失败自动重传次数
          maxChunkRetries: 3,
          fileParameterName: 'file',
          testChunks: true,
          // 服务器分片校验函数, 秒传及断点续传基础
          checkChunkUploadedByResponse: function(chunk, message) {
            const objMessage = JSON.parse(message)
            const respData = objMessage.data
            if (respData.skipUpload) {
              return true
            }
            return (respData.uploaded || []).indexOf(chunk.offset + 1) >= 0
          },
          query: (file, chunk) => {
            return {
              channelCode: resData.channelCode,
              multiparts: ''
            }
          },
          headers: {
            Authorization: 'Bearer ' + resData.token
          },
          withCredentials: false
        }
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传视频文件',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '获取 OSS 服务器地址失败, 暂时无法上传视频文件',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })

    getVideoCoverChannelInfo().then(res => {
      if (res.code === 0) {
        const resData = res.data
        this.form.coverChannelId = resData.channelCode
        this.imgData.channelCode = resData.channelCode
        this.imgOssUrl = resData.ossUrl
        this.imgHeaders.Authorization = 'Bearer ' + resData.token
      } else {
        this.$notify({
          title: '提示',
          message: '获取 OSS 服务器地址失败, 暂时无法上传视频封面',
          type: 'error',
          duration: 3000
        })
      }
    }).catch(error => {
      this.$notify({
        title: '获取 OSS 服务器地址失败, 暂时无法上传视频封面',
        message: error.message,
        type: 'warning',
        duration: 3000
      })
    })
    this.getVideoCategory()
  },
  mounted() {
  },
  methods: {
    // ****************************************************************************************************************
    onFileAdded(file) {
      if (file.file.size > 1024 * 1024 * 1024 * 20) {
        file.cancel()
        this.$notify({
          title: '提示',
          message: '文件应小于 20GB',
          type: 'warning',
          duration: 3000
        })
        return
      }
      this.setTitle(file.file.name)
      this.processVideo(file.file)

      file.pause()
      hashFile(file.file).then(result => {
        this.startUpload(result.sha256sum, file)
      })
    },
    startUpload(sha256sum, file) {
      file.uniqueIdentifier = sha256sum
      file.resume()
    },
    onFileProgress(rootFile, file, chunk) {
    },
    onFileSuccess(rootFile, file, response, chunk) {
      const res = JSON.parse(response)
      if (res.code === 0) {
        const resData = res.data
        this.form.videoFileId = resData.uploadId
        this.$notify({
          title: '提示',
          message: '视频已上传',
          type: 'warning',
          duration: 3000
        })
      } else {
        this.$notify({
          title: '提示',
          message: '视频文件上传失败',
          type: 'warning',
          duration: 3000
        })
      }
    },
    onFileError(rootFile, file, response, chunk) {
      this.$notify({
        title: '提示',
        message: '视频文件上传错误',
        type: 'warning',
        duration: 3000
      })
    },
    // ****************************************************************************************************************
    // 选择视频后获取视频的分辨率和时长, 并截取第一秒的内容作为封面
    processVideo(file) {
      return new Promise((resolve, reject) => {
        const canvas = document.createElement('canvas')
        const canvasCtx = canvas.getContext('2d')

        const videoElem = document.createElement('video')
        const dataUrl = window.URL.createObjectURL(file)
        // 当前帧的数据是可用的
        videoElem.onloadeddata = function() {
          resolve(videoElem)
        }
        videoElem.onerror = function() {
          reject('video 后台加载失败')
        }
        // 设置 auto 预加载数据， 否则会出现截图为黑色图片的情况
        videoElem.setAttribute('preload', 'auto')
        videoElem.src = dataUrl
        // 预加载完成后才会获取到视频的宽高和时长数据
        videoElem.addEventListener('canplay', this.onCanPlay(videoElem, canvas, canvasCtx))
      })
    },
    onCanPlay(videoElem, canvas, canvasCtx) {
      setTimeout(() => {
        // 视频视频分辨率
        const videoWidth = videoElem.videoWidth
        const videoHeight = videoElem.videoHeight
        this.form.width = videoWidth
        this.form.height = videoHeight
        this.form.duration = videoElem.duration

        videoElem.pause()
        // 设置画布尺寸
        canvas.width = videoWidth
        canvas.height = videoHeight
        canvasCtx.drawImage(videoElem, 0, 0, canvas.width, canvas.height)
        // 把图标base64编码后变成一段url字符串
        const urlData = canvas.toDataURL('image/jpeg')
        if (typeof urlData !== 'string') {
          alert('urlData不是字符串')
          return
        }

        var arr = urlData.split(',')
        var bstr = atob(arr[1])
        var n = bstr.length
        var u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }

        const coverFile = new File([u8arr], 'cover.jpg', { type: 'image/jpeg' })
        if (coverFile instanceof File) {
          if (coverFile.size === 0) {
            this.$notify({
              title: '提示',
              message: '自动获取视频封面失败，请手动选择！',
              type: 'warning',
              duration: 3000
            })
            return
          }

          const formData = new FormData()
          formData.append('file', coverFile)
          formData.append('channelCode', this.imgData.channelCode)
          fetch(this.imgOssUrl, {
            headers: this.imgHeaders,
            method: 'POST',
            credentials: 'include',
            body: formData
          }).then(response => response.json()).then(json => {
            if (json.code === 0) {
              this.coverUrl = URL.createObjectURL(coverFile)
              const resData = json.data
              this.form.coverFileId = resData.uploadId
            } else {
              this.$notify({
                title: '提示',
                message: '视频封面上传失败，请重试！' + json.msg,
                type: 'warning',
                duration: 3000
              })
            }
          }).catch(e => {
            return null
          })
        }
      }, 1000) // 1000毫秒，就是截取第一秒，2000毫秒就是截取第2秒，视频1秒通常24帧，也可以换算成截取第几帧。
      // 防止拖动进度条的时候重复触发
      // videoElem.removeEventListener('canplay', arguments.callee)
    },
    // ****************************************************************************************************************
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG) {
        this.$message.error('封面图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('封面图片大小不能超过 10MB!')
      }
      return isJPG && isLt2M
    },
    handleAvatarSuccess(res, file) {
      if (res.code === 0) {
        const resData = res.data
        this.coverUrl = URL.createObjectURL(file.raw)
        this.form.coverFileId = resData.uploadId
      } else {
        this.$notify({
          title: '提示',
          message: '视频封面上传失败，请重试！' + res.msg,
          type: 'warning',
          duration: 3000
        })
      }
    },
    handleAvatarError(error, file) {
      this.$notify({
        title: '提示',
        message: '视频封面上传失败，请重试！' + error,
        type: 'warning',
        duration: 3000
      })
    },
    handleOnChange(file, fileList) {
    },
    // ****************************************************************************************************************
    setTitle(title) {
      if (title.length > 50) {
        this.form.title = title.substring(0, 50)
        this.form.description = title
      } else {
        this.form.title = title
      }
    },
    getVideoCategory() {
      videoRegion().then(res => {
        if (res.code === 0) {
          const resData = res.data
          for (let i = 0; i < resData.length; i++) {
            const name = resData[i].label
            const id = resData[i].value
            this.pCategoryList.push({ label: name, value: id })
            this.categoryMap.Set(id, resData[i].children)
          }
        } else {
          this.$notify({
            title: '提示',
            message: res.msg,
            type: 'warning',
            duration: 3000
          })
        }
      }).catch(error => {
        this.$notify({
          title: '提示',
          message: error.message,
          type: 'error',
          duration: 3000
        })
      })
    },
    getCategory(id) {
      // 重置子分区，清除前一次选择分区时留下的缓存
      this.categoryList = []
      for (const item of this.categoryMap.Get(id)) {
        this.categoryList.push({ label: item.label, value: item.value })
      }
    },
    // 根据输入的标签获取相似的标签
    getRecommendTags(tags) {
      for (const tag of tags) {
        console.log(tag)
      }
    },
    onSubmit() {
      if (!this.form.videoFileId) {
        this.$notify({
          title: '提示',
          message: '你还没有上传视频',
          type: 'warning',
          duration: 3000
        }
        )
        return
      }

      if (!this.form.coverFileId) {
        this.$notify({
          title: '提示',
          message: '你还没有上传视频封面',
          type: 'warning',
          duration: 3000
        }
        )
        return
      }

      if (this.form.title === '' || this.form.categoryId === -1) {
        this.$notify({
          title: '提示',
          message: '分区和稿件标题不能为空',
          type: 'warning',
          duration: 3000
        }
        )
        return
      }

      if (this.form.tags.length === 0 || this.form.tags.length > 10) {
        this.$notify({
          title: '提示',
          message: '标签最少 1 个, 最多 10 个',
          type: 'warning',
          duration: 3000
        }
        )
        return
      }

      if (this.scheduledPubDate !== null) {
        this.form.scheduledTime = Date.parse(this.scheduledPubDate)
        /* this.$notify({
          title: '提示',
          message: '定时发布的时间必须在当前时间之后',
          type: 'warning',
          duration: 3000
        })*/
      }
      this.$emit('video-publish', this.form)
    }
  }
}
</script>

<style>
.uploader-example {
  width: 500px;
  padding: 15px;
  margin: 40px auto 0;
  font-size: 12px;
  box-shadow: 0 0 10px rgba(0, 0, 0, .4);
}
.uploader-example .uploader-btn {
  margin-right: 4px;
}
.uploader-example .uploader-list {
  max-height: 440px;
  overflow: auto;
  overflow-x: hidden;
  overflow-y: auto;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 240px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 320px;
  height: 240px;
  display: block;
}
</style>
